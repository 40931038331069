import { LicenseType } from '@mri-platform/import-export/common-state';
import { EntityName } from '@mri-platform/import-export/core';
import { AuthzContextsActionMap } from '@mri-platform/resource-authz';
import { createModelMetadata } from '@mri-platform/shared/core';
import { NEW_ENTITY_ID } from '@mri-platform/shared/entity';

export interface Client {
  id: number;
  name: string;
  licenseId: string;
  licenseType: LicenseType;
  addonRecords: number;
  previousAddonRecords?: number;
  reason: string;
}

export const Client = createModelMetadata<Client>({
  entityName: EntityName.Client,
  authorization: {
    ...AuthzContextsActionMap.crudFor(EntityName.Client),
    ['ViewPage']: {
      action: ['ViewPage'],
      resource: EntityName.Client
    }
  },
  sortComparer: (a, b) => a.licenseId.localeCompare(b.licenseId)
});

export function createClient(): Client {
  return {
    id: NEW_ENTITY_ID,
    name: '',
    licenseId: '',
    licenseType: LicenseType.Standard,
    addonRecords: 0,
    reason: ''
  };
}

export const StandardLicenseTypeTitle = `${LicenseType.Standard} Edition.`;
export const StandardlicenseTypeDescription =
  'Access is available to pre-made jobs, mapping options and rules. For custom jobs, maps or rules, talk to your salesperson about Premium edition.';
export const SupportRoleIds = [1, 6, 7, 8];
